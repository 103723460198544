/*----- Main View -----*/
#main-page-background:not(a) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 93vh;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)), url(../../public/Images/Misc/Background.jpg);
    background-position: center;
    background-size: cover;
    text-align: center;
    vertical-align: middle;
    align-items: center;
}
#main-page-background h1 {
    color: #fff;
}
#main-page-background a {
    width: fit-content;
}