/*----- Title/Overview/Description -----*/
.overview .title h1 {
    margin-bottom: 0;
    padding-bottom: 0;
}
.overview .title h3 {
    margin-top: 0;
    padding-top: 0;
    color: var(--secondary-color)
}
.description p {
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0px rgba(0,0,0,0.2);
    margin-top: 10px;
}
.three-col .fa {
    font-size: 20vh;
    color: var(--secondary-color);
}

/*----- Gallery -----*/
.expand-gallery {
    display: none;
}
.expand-gallery .project-col {
    animation-name: slide-down;
    animation-duration: 1s;
}
.gallery .fa {
    font-size: 50px;
    margin-top: 10px;
    display: block;
}
.gallery .fa-arrow-up {
    display: none;
}
.gallery .fa-arrow-down:hover, .gallery .fa-arrow-up:hover {
    color: var(--secondary-color);
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #000;
}

/*----- GitHub -----*/
.github .image img {
    max-width: 70%;
}