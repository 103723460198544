* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Slab', serif;
}

:root {
    --secondary-color: #f44336;
    --dark-grey: #444;
}
.section {
    width: 80%;
    min-height: calc(93vh - 200px);
    text-align: center;
    margin: 10px auto;
    padding: 10px 0;
}

/*----- Animations -----*/
@keyframes zoom {
    from {transform: scale(0)} 
    to {transform: scale(1)}
}
@keyframes slide-down {
    from {transform: translateY(-100%); opacity: 0.0;}
    to {transform: translateY(0); opacity: 1.0}
}

/*----- Fonts -----*/
h1 {
    font-size: 60px;
    font-weight: 900;
    color: #000;
    padding: 10px;
}
h2 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
    padding: 10px;
    margin-top: 10px;
}
h3 {
    font-size: 26px;
    font-weight: 700;
    color:#000;
    padding: 10px;
}
p {
    color: #777;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 10px;
}

/*----- Organizers -----*/
.row {
    display: flex;
    justify-content: space-between;
}

/*----- Screen Size Variation -----*/
@media(max-width: 700px) {
    .row {
        flex-direction: column;
    }

    h1 {
        font-size: 12vw;
    }
    h2 {
        font-size: 8vw;
    }
    h3 {
        font-size: 6vw;
    }
    p {
        font-size: 3.5vw;
    }
}