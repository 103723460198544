.modal {
    display: none;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: none;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
    vertical-align: middle;
    align-items: center;
}
.modal-content {
    margin: auto;
    display: block;
    max-width: 100vw;
    max-height: 90vh;
    margin-bottom: 0;
    height: 100%;
}
#caption {
    margin: auto;
    margin-top: 0;
    display: block;
    width: 80%;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
}
.modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.5s;
}
.close {
    position: absolute;
    top: 1vh;
    right: 35px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}
.close:hover,
.close:focus {
    color: var(--secondary-color);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
    text-decoration: none;
    cursor: pointer;
}

/*----- Screen Size Variation -----*/
@media (max-width: 700px) {
    .modal-content {
        width: 100vw;
        height: auto;
        max-height: 75vh;
    }
}